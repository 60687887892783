.modal-img-container {
  max-height: 80vh;
  height: fit;
  object-position: center;
  object-fit: contain;
}

.gallery {
  display: grid;
  width: 100vw;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  padding: 4px;
}

.v-stretch {
  grid-row: span 2;
}

.h-stretch {
  grid-column: span 2;
}

.big-stretch {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  --f: 1.1; /* the scale factor */
  clip-path: inset(0);
  transition: .4s;
}

.gallery div img:hover {
  clip-path: inset(calc((1 - 1/var(--f)) * 50%));
  scale: var(--f);
}

@media(max-width: 560px) {
  .v-stretch {
    grid-row: span 1;
  }

  .h-stretch {
    grid-column: span 1;
  }

  .big-stretch {
    grid-column: span 1;
    grid-row: span 1;
  }
}