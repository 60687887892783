@property --s {
  syntax: "<length>";
  initial-value: 0px;
  inherits: true;
}

.frame {
  --t: 3px;  /* control the thickness (corner = 3*edge) */
  --s: 40px; /* control the size of the corners (that also affect the size of the edges) */
  --g: 8px;  /* control the gap */
  --c: #755C3B;

  padding: 2.5rem 4rem;
  border: 0 var(--t) var(--t) var(--t) solid #0000;
  border-radius: 10px;
  background:
    conic-gradient(at var(--s) calc(3*var(--t)),#0000 75%,var(--c) 0)
     0 0/calc(100% - var(--s)) calc(100% - 3*var(--t)) border-box,
    conic-gradient(at calc(3*var(--t)) var(--s),#0000 75%,var(--c) 0)
     0 0/calc(100% - 3*var(--t)) calc(100% - var(--s)) border-box,
    linear-gradient(  0deg,var(--c) calc(2*var(--t)),#0000 0)
      50% var(--t)/calc(100% - 2*(var(--s) + var(--g))) 100%
      repeat-y padding-box,
    linear-gradient(-90deg,var(--c) calc(2*var(--t)),#0000 0)
      var(--t) 50%/100% calc(100% - 2*(var(--s) + var(--g)))
      repeat-x padding-box;
  transition: --s .5s;
  max-width: 98vw;
}

.frame:hover {
   --s: 80px;
}

/* Circle container styles */
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Circle styles */
.circle {
  width: 50px;
  height: 50px;
  background-color: #DFD0C0;
  border-radius: 50%;
  animation: pulse 2s infinite alternate;
}

/* Animation keyframes */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}