.footer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.footer:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65); /* Change the color and opacity as needed */
}