@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply lg:text-5xl text-4xl font-serif;
  }
  h2 {
    @apply lg:text-4xl text-3xl font-serif;
  }
  h3 {
    @apply lg:text-2xl text-xl font-serif;
  }

  h4 {
    @apply lg:text-xl text-lg font-serif;
  }
}

@font-face {
  font-family: 'Drainwood';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../public/fonts/Drainwood.otf');
}

@font-face {
  font-family: 'Megante';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('../public/fonts/Megante.otf');
}

.section {
  @apply w-[1000px] max-w-[90%] mx-auto pt-28 flex flex-col items-center gap-8;
}

.section-title {
  @apply text-center;
}

.section-description {
  @apply px-4 lg:px-28;
  color: dimgray;
}

.card-description {
  color: dimgray;
}

.fade-in {
  opacity: 0;
  transition: opacity 350ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}

.from-left {
  transform: translateX(-25%);
  opacity: 0;
}

.from-right {
  transform: translateX(25%);
}

.from-left,
.from-right {
  transition: all 450ms ease-in;
}

.from-left.appear,
.from-right.appear {
  transform: translateX(0);
  opacity: 1;
}

.from-bottom {
  transform: translateY(50%);
  opacity: 0;
  transition: all 450ms ease-in;
}

.from-bottom.appear {
  transform: translateY(0);
  opacity: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: Megante, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3ece6;
  overflow-x: hidden;
}

p {
  font-family: Megante, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-form-item-explain-error {
  font-family: Megante, san-serif;
}