.input {
  transition: 0.5s;
  width: 100%;
  height: 45px;
  font-family: Megante;
}

.text-area {
  transition: 0.5s;
  width: 100%;
  font-family: Megante;
  max-height: 150px;
  overflow-y: auto;
  resize: none;
  padding: 0.8rem;
}

.submit-button {
  font-family: Megante;
  width: 100%;
  height: 45px;
  @apply bg-[#826542] hover:text-white hover:bg-stone-600 border-none text-white;
}
